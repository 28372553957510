<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiImageFocalProps {
  image: object;
  rounded?: boolean;
}
const props = withDefaults(defineProps<CiImageFocalProps>(), {
  rounded: true,
});

/********************
 * REFS & VARS       *
 ********************/
const wrapperClasses = computed(() => {
  return {
    'h-full': true,
    'img-focal': true,
    'relative': true,
    'rounded': props.rounded,
    'overflow-hidden': true,
  };
});

const x = computed(() => {
  return props.image.focal_point?.x ? `${props.image.focal_point?.x + 1 * 50}%` : '50%';
});

const y = computed(() => {
  return props.image.focal_point?.y ? `${(props.image.focal_point?.y + 1) * 50}%` : '50%';
});
</script>

<template>
  <div :class="wrapperClasses">
    <img
      class="size-full object-cover"
      :style="`object-position: ${x} ${y}`"
      :src="props.image.file_url"
      loading="lazy"
      alt=""
    >
  </div>
</template>
